export default [
  {
    header: 'apps',
  },
  {
    title: 'Academic Year',
    icon: 'CalendarIcon',
    route: 'apps-academic-year',
  },
  {
    title: 'School Fees',
    icon: 'BriefcaseIcon',
    route: 'apps-fees'
  },
  {
    title: 'Gallery',
    icon: 'ImageIcon',
    route: 'apps-gallery'
  },
  {
    title: 'Chat',
    icon: 'MessageSquareIcon',
    route: 'apps-chat'
  },
  {
    title: 'Reviews',
    icon: 'StarIcon',
    route: 'apps-review'
  },
  {
    title: 'Feedback',
    icon: 'ActivityIcon',
    route: 'apps-users-feedback'
  },
  {
    title: 'Staff',
    icon: 'UsersIcon',
    route: 'apps-staff'
  },
  {
    title: 'Shop',
    icon: 'ShoppingCartIcon',
    route: 'apps-trec-shop'
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    route: 'apps-settings-security'
  },
]
